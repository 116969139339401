<template>
  <div class="clients-list-container">
    <alert v-if="OCF21BInvoicesLoading" class="shadow-card" />
    <div
      class="card shadow-card mobile-tablet-no-card"
      v-if="!OCF21BInvoicesLoading"
    >
      <div class="card-body">
        <div class="text-dark col-12 col-md-auto mb-3 mb-md-0 p-0">
          <span>
            <i
              class="fas fa-spin fa-circle-notch"
              v-if="OCF21BInvoicesLoading"
            ></i>
            <b v-else>
              {{ getFilteredInvoices.length }}
              Invoice{{
                OCF21BInvoicesLoading || getFilteredInvoices.length != 1
                  ? "s"
                  : ""
              }}
            </b>
          </span>
        </div>
        <div class="form-group mb-0">
          <div class="criteria-dropdown">
            <select-control
              v-model="searchCriteria"
              :options="searchCriteriaOptions"
              labelClass="col-4"
              wrapClass="align-items-center"
            >
              Search Criteria:
            </select-control>
          </div>
          <div class="search-input">
            <input
              type="search"
              class="form-control pr-5"
              v-model.trim="searchContent"
              placeholder="Search by Selected Criteria..."
            />
            <i class="fa fa-search search-icon text-primary"></i>
            <i
              v-if="searchContent"
              class="fas fa-times cancel-icon"
              @click="searchContent = ''"
            ></i>
          </div>
        </div>
      </div>
      <div class="table-container p-1 p-lg-0" v-if="getFilteredInvoices.length">
        <table class="table table-striped mb-0 responsive-mobile-tablet-table">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Draft Date</th>
              <th>Affiliated Plan Number</th>
              <th>File Name</th>
              <th>Invoice Number</th>
              <th>Invoice Amount</th>
              <th class="text-center">Approve</th>
            </tr>
            <tr>
              <th colspan="7"></th>
              <th>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    @change="approveAll"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Approve All
                  </label>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="invoice in getFilteredInvoices"
              :key="invoice.invoice_number"
            >
              <td>
                <b class="mobile-tablet-only mr-2">First Name:</b>
                {{ invoice.first_name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Last Name:</b>
                {{ invoice.last_name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Draft Date:</b>
                {{ formattedDate(invoice.draft_date) }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Affiliated Plan Number:</b>
                {{ invoice.affiliated_plan_number }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">File Name:</b>
                {{ invoice.file_name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Invoice Number:</b>
                {{ invoice.invoice_number }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Invoice Amount:</b>
                {{
                  parseFloat(invoice.invoice_amount)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
              <td
                class="
                  td-actions
                  d-flex
                  justify-content-left justify-content-lg-center
                "
              >
                <b class="mobile-tablet-only mr-2">Approve:</b>
                <input type="checkbox" v-model="invoice.approved" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="row justify-content-end mr-3 my-2"
        v-if="getFilteredInvoices.length"
      >
        <i
          class="fas fa-spin fa-circle-notch"
          v-if="approvedInvoicesLoading"
        ></i>
        <button
          v-else
          class="btn btn-outline-blue"
          @click="submitApprovedInvoices"
        >
          Submit Selected Invoices
        </button>
      </div>
      <div class="card-body py-0" v-if="!getFilteredInvoices.length">
        <alert :hideLoader="true"> No Invoices </alert>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import * as dayjs from "dayjs";
import Swal from "sweetalert2";

export default {
  name: "OCF21B",
  data() {
    return {
      searchContent: "",
      selectedInvoicesToApprove: [],
      searchCriteria: "first_name+last_name",
      searchCriteriaOptions: [
        { value: "first_name+last_name", label: "First and Last Name" },
        { value: "affiliated_plan_number", label: "Affiliated Plan Number" },
        { value: "file_name", label: "File Name" },
        { value: "invoice_number", label: "Invoice Number" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user || {},
      OCF21BInvoices: (state) => state.HCAI.OCF21B.data,
      OCF21BInvoicesLoading: (state) => state.HCAI.OCF21B.loading,
      approvedInvoicesLoading: (state) => state.HCAI.approvedInvoicesLoading,
    }),
    getFilteredInvoices: function () {
      const vm = this;
      if (this.searchContent) {
        const regex = new RegExp(`.*${this.searchContent}.*`, "i");
        return this.OCF21BInvoices.filter((invoice) => {
          const searchCriteria = vm.searchCriteria.split("+");
          let search = ``;
          searchCriteria.forEach((_searchCriteria) => {
            search = `${search} ${invoice[_searchCriteria]}`;
          });
          search = search.trim();
          return regex.test(search);
        });
      }
      return this.OCF21BInvoices;
    },
  },
  mounted() {
    this.getOCF21BInvoices();
  },
  methods: {
    ...mapActions({
      getOCF21BInvoices: "HCAI/getOCF21BInvoices",
      submitApproveOCF21BInvoices: "HCAI/approveOCF21BInvoices",
      approveAllOCF21B: "HCAI/approveAllOCF21B",
    }),
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("YYYY-MM-DD");
      } else return "";
    },
    approveAll: function (event) {
      this.approveAllOCF21B(event.target.checked);
    },
    submitApprovedInvoices: function () {
      this.selectedInvoicesToApprove = this.OCF21BInvoices.filter((invoice) => {
        return invoice.approved;
      }).map((invoice) => {
        return invoice.invoice_number;
      });
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to submit selected invoices ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.submitApprovedInvoices(vm.selectedInvoicesToApprove).then(
              (res) => {
                if (res) {
                  vm.getOCF21BInvoices();
                }
              }
            );
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.count-alert {
  border: 0;
  background-color: transparent;
  display: inline-block;
}
.col-12 > div {
  width: 100%;
}
.cancel-icon,
.search-icon {
  z-index: 99;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 15px;
}
.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  height: fit-content;
  .criteria-dropdown {
    flex-basis: 35%;
  }
  .search-input {
    flex: 1;
    height: fit-content;
    position: relative;
  }
  @media (max-width: 868px) {
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .responsive-mobile-tablet-table {
    margin-top: 4px;
  }
  .card-body {
    background-color: white;
    padding: 12px 10px !important;
  }
}
</style>
